.all-deals-card {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 15px;
}

.all-deals-table {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
}

.all-deals-table>thead>tr>th {
  padding-top: 20px;
  padding-bottom: 20px;
}

.all-deals-table>thead>tr>th>div>div {
  width: 0;
}

.all-deals-table>thead>tr>th:nth-child(6) {
  min-width: 210px;
}

.all-deals-table>thead>tr>th:nth-child(7),
.all-deals-table>thead>tr>th:nth-child(11) {
  min-width: 200px;
}

.all-deals-table>thead>tr>th:nth-child(1) {
  width: 20px;
}

.all-deals-table>thead>tr>th:nth-child(5),
.all-deals-table>thead>tr>th:nth-child(2),
.all-deals-table>thead>tr>th:nth-child(3),
.all-deals-table>thead>tr>th:nth-child(8),
.all-deals-table>thead>tr>th:nth-child(9),
.all-deals-table>thead>tr>th:nth-child(10),
.all-deals-table>thead>tr>th:nth-child(12) {
  min-width: 150px;
  width: 150px;
}

.filterName {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #404040;
  padding: 10px 0;
}

.shell-pagination-item-active a {
  color: white;
}

div:has(> .shell-pagination) {
  border-top: 1px solid #e5e5e5;
  background-color: white;
}

.all-deals-table th:last-child,
.all-deals-table td:last-child {
  position: sticky;
  right: 0;
  background: #fff;
}

.modal-link {
  color: #336094;
  cursor: pointer;
}

.shell-empty-state {
  margin-right: 400px;
}

.shell-empty-state div {
  display: none;
}

.deals-count {
  font-weight: 600;
  size: 14px;
  font-family: 'Shell Font', sans-serif;
}

.non-edit-form .shell-text-input-input {
  background-color: white !important;
  color: black !important;
}

.shell-modal-container-footer {
  padding-bottom: 50px;
}
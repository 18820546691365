.btn-size {
  width: auto;
  min-height: 30px!important; 
  font-size: 14px!important;

}
.heading-label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  justify-content:center;
  margin-left: 17%;
  white-space: nowrap;
}

.acc-label {
  color: #0097BB !important;
  float:left; 
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.manual-entry-label {
  float: left;
  padding-bottom: 20px;
}

.kpi-label {
  float: left;
  text-align: left;
  margin-left: 5px;
}

.download-pdf {
  top: 10px;
  display: flex;
  justify-content: flex-end;
}

.download-step3 {
  margin-right: 10px !important;
}

.sub-heading { 
  font-style: normal;
  font-weight: 650;
  font-size: 16px;
  line-height: 22px;
}

.sub-text {  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}

.align-btns-right {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;  
}

.align-btns-right-input-table {  
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.margin-right { 
  margin-right: 12px !important;
}

.margin-top {
  margin-top: 20px
}

.flex-display-start {
  justify-content: flex-start;
  display: flex;
}
/* Empty state image and text styling - Start*/

.empty-state-label-text {  
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.empty-state-sub-label {  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.empty-state-image{
text-align: center;
}
/* Empty state image and text styling -End */
.stepThreeCard {
  width: 650px !important;
  height: auto !important;
  border-radius: 16px !important;
  box-shadow: 3px 3px 3px 3px rgba(163, 161, 161, 0.3) !important;
  margin-bottom: 50px;
}

.leftAlign {
  text-align: left;
}

.cardColValue {
  background-color: #eab219 !important;
}

.step-text-align {
  margin-left: -55px;
  margin-top: 25px;
  float: right;
  padding-bottom: 2px;
}

.step-text-align-step3 {
  margin-top: 0px;
  margin-left: 0px;
  float: right;
  text-align: left;
  padding-bottom: 2px;
}
.shell-accordion-panel-header-text {
  padding-left:10px;
  padding-bottom:20px;
  padding-top:20px;
}
.shell-accordion-panel-expand-icon {
  margin-left:20px;
}
.accordion-box-shadow {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%), 0px 0px 2px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  background: white;
}

.feedback-btn-style {
  height: 30px;
  justify-content: flex-end;
  width: 135px;
  border:none!important;   
}

.feedback-btn-div {
  margin-top:5px!important;
  margin-left:6px;
}

.display-flex { 
  display:flex;
}

.link-styling {
  margin-top:7px;
  margin-left:6px;
  cursor:pointer;
  color:rgb(52, 52, 52);
  font-weight: 500;
}
.display-flex a:link {
  text-decoration: none;
}
.display-flex a:hover {
  text-decoration: underline;
}
.global-header-title-color {
  font-weight: 800!important;
  color:  #DD1D21 !important;
  margin-left:-19px;
}

.global-header-sub-title-color {    
  font-style: normal;
  font-weight: 800!important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.045em;
  color: #0097BB !important;
}

.click-here a {
color: #32619F ;
text-decoration:underline;
cursor:pointer;
}

#tool-feedback-btn:hover {
  text-decoration:underline;
  background-color:none;
  border:none;
}

.versioning {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4E545A;
}

.uom-tool-styling {
  position: relative;
  left: -65.2%;
}

.uom-icon {
  width:15px !important;
}

.shell-table-thead .shell-table-cell {
  font-weight:700 !important ;
}

.App header {
  padding-left: 3%;
  width:100%;
}

.kpiCardMaxWidth {
  max-width: 50%;
}

.hideKpiMessage {
  display: none !important;
}
.accordion-box-shadow .shell-accordion-panel-content{
  height: auto !important;
}
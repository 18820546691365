.link-btn-div {
  margin-top: 5px;
}
.link-btn-div .btn-style {
  width: auto;
  border: none;
  min-height: 30px;
  font-size: 14px;
  padding: 0 8px 0 8px;
}
.link-btn-div .btn-style:hover {
  text-decoration: underline;
  background-color: none;
  border: none;
}
